import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import type { EntryPointProps } from 'react-relay';
import AppBase from '@atlassian/jira-app-base/src/index.tsx';
import { FormSubmissionContextProvider } from '@atlassian/jira-business-form/src/ui/form-submission/form-submission-context-provider/index.tsx';
import { FormSubmissionRoot } from '@atlassian/jira-business-form/src/ui/form-submission/index.tsx';
import { LICENSED_PRODUCTS } from '@atlassian/jira-common-util-get-tenant-context/src/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { RedirectToLoginPage } from '@atlassian/jira-spa-apps-common/src/redirect/login/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useLicensedProducts } from '@atlassian/jira-tenant-context-controller/src/components/licensed-products/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { Redirect } from '@atlassian/react-resource-router';

type JiraSoftwareFormSubmitAppType = EntryPointProps<{}, {}, {}, {}>;

const JiraSoftwareFormSubmitApp: ComponentType<JiraSoftwareFormSubmitAppType> = () => {
	const accountId = useAccountId();
	const licensedProducts = useLicensedProducts();

	if (!accountId) {
		return <RedirectToLoginPage />;
	}

	const hasSoftwareLicense = licensedProducts?.[LICENSED_PRODUCTS.JIRA_SOFTWARE];
	if (!hasSoftwareLicense) {
		return <Redirect to="/jira/projects" />;
	}

	return (
		<UFOSegment name="jira-software-form-submit">
			<AppBase id="jira-software-form-submit">
				<FormSubmissionContextProvider>
					<FormSubmissionRoot />
				</FormSubmissionContextProvider>
				<SubProductUpdater subProduct="software" />
			</AppBase>
		</UFOSegment>
	);
};

export default JiraSoftwareFormSubmitApp;
